<template>
  <div class="starry">
    <div class="header" id="headerPc">
      <div style="display: flex" v-if="decipherment">
        <img
          @click="index"
          class="logo"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png"
          alt=""
        />
        <div class="title engreg" @click="index" style="margin-left: 60px">
          HOME
        </div>
        <div class="title engreg" @click="party">PARTY</div>
        <div class="title engreg" @click="stake">STAKING</div>
        <div class="title engreg" @click="world">GAMBLER HALL</div>
        <!-- <div class="title engreg" @click="changeCloth">World Cup Outfits</div> -->
        <div class="title engreg">3D PARTY</div>
      </div>
      <div style="display: flex" v-else>
        <img
          @click="index"
          class="logo"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png"
          alt=""
        />
        <div class="title chinreg" style="margin-left: 60px" @click="index">
          首页
        </div>
        <div class="title chinreg" @click="party">PARTY</div>
        <div class="title chinreg" @click="stake">质押</div>
        <div class="title chinreg" @click="world">世界杯赌王</div>
        <!-- <div class="title chinreg" @click="changeCloth">世界杯换装</div> -->
        <div class="title chinreg">3D PARTY</div>
      </div>
      <ul class="header_right">
        <li
          class="title1"
          @click="revise"
          :class="decipherment ? 'engreg' : 'chinreg'"
        >
          {{ eng }}
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/xia.png"
            alt=""
          />
          <div class="cha_text" v-show="revise_text" @click="deciphermented">
            {{ chin }}
          </div>
        </li>
        <li class="img1" @click="openseaview">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/opensea1.png"
            alt=""
            srcset=""
          />
        </li>
        <li class="img">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitter1.png"
            alt=""
            srcset=""
            @click="twitter"
          />
        </li>
        <li class="img">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png"
            alt=""
            srcset=""
            @click="redirect"
          />
        </li>
      </ul>
    </div>
    <div class="headerM" id="headerMM">
      <div>
        <img
          @click="index"
          class="logo"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png"
          alt=""
        />
      </div>
      <div class="headerM_ul">
        <div class="menubox" @click="menu">
          <img
            class="menu"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/menu.png"
            alt=""
          />
          <div class="del" v-if="menudel">
            <div v-if="decipherment">
              <p class="text engreg" @click="index">HOME</p>
              <p class="text engreg" @click="party">PARTY</p>
              <p class="text engreg" @click="refresh">STAKING</p>
              <p class="text engreg" @click="world">GAMBLE</p>
              <!-- <p class="text engreg" @click="changeCloth">OUTFITS</p> -->
              <p class="text engreg">3D PARTY</p>
              <p class="text engreg" @click="openseaview">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png"
                  alt=""
                  srcset=""
                />
                <span>OPENSEA</span>
              </p>
              <p class="text engreg" @click="twitter">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitterM.png"
                  alt=""
                  srcset=""
                />
                <span>TWITTER</span>
              </p>
              <p class="text engreg" @click="redirect">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png"
                  alt=""
                  srcset=""
                />
                <span>DISCORD</span>
              </p>
              <p class="text engreg" @click="deciphermented">中文</p>
            </div>
            <div v-else>
              <p class="text chinreg" @click="index">首页</p>
              <p class="text chinreg" @click="party">PARTY</p>
              <p class="text chinreg" @click="refresh">质押</p>
              <p class="text chinreg" @click="world">世界杯赌王</p>
              <!-- <p class="text chinreg" @click="changeCloth">世界杯换装</p> -->
              <p class="text chinreg">3D PARTY</p>
              <p class="text engreg" @click="openseaview">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png"
                  alt=""
                  srcset=""
                />
                <span>OPENSEA</span>
              </p>
              <p class="text engreg" @click="twitter">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitterM.png"
                  alt=""
                  srcset=""
                />
                <span>TWITTER</span>
              </p>
              <p class="text engreg" @click="redirect">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png"
                  alt=""
                  srcset=""
                />
                <span>DISCORD</span>
              </p>
              <p class="text chinreg" @click="deciphermented">ENGLISH</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <iframe
      src="https://starrynift.art/backtofuture/pekingweb"
      id="iframeContain"
    >
    </iframe>
  </div>
</template>
<script>
export default {
  name: "3DPARTY",
  data() {
    return {
      decipherment: true,
      chin: "中文",
      eng: "ENGLISH",
      revise_text: false,
      menudel:false
    };
  },
  watch: {
    decipherment: function (oldValue) {
      // this.revise_text= false;
      if (oldValue == true) {
        this.chin = "中文";
        this.eng = "ENGLISH";
      } else {
        this.chin = "ENGLISH";
        this.eng = "中文";
      }
    },
  },
  mounted() {
    if (this.$route.params.decipherment) {
      this.decipherment = true;
    } else {
      this.decipherment = false;
    }
  },
  methods: {
    menu() {
      this.menudel = !this.menudel;
    },
    party() {
      this.$router.push({
        name: "party",
        params: { decipherment: this.decipherment },
      });
    },
    changeCloth() {
      this.$router.push({
        name: "worldCupCloth",
        params: { decipherment: this.decipherment },
      });
    },
    world() {
      this.$router.push({
        name: "worldCup",
        params: { decipherment: this.decipherment },
      });
    },
    stake() {
      this.$router.push({
        name: "stake",
        params: { decipherment: this.decipherment },
      });
    },
    index() {
      this.$router.push({
        name: "mint",
        params: { decipherment: this.decipherment },
      });
    },
    starry() {
      this.$router.push({
        name: "starry",
        params: { decipherment: this.decipherment },
      });
    },
    twitter() {
      window.open("https://twitter.com/Peking_Monsters");
    },
    redirect() {
      window.open("https://discord-verify.pekingmonsters.xyz/v2/redirect");
    },
    openseaview() {
      window.open("https://opensea.io/collection/pekingmonsters");
    },
    terms() {
      window.open(
        "https://master.ohdat.io/peking_monsters/terms.pdf",
        "_blank"
      );
    },
    revise() {
      this.revise_text = !this.revise_text;
    },
    deciphermented() {
      this.decipherment = !this.decipherment;
    },
  },
  destroyed() {
    if (window.screen.width < 1024) {
      // window.addEventListener("scroll", this.windowScrollM, true);
      window.removeEventListener("scroll", this.windowScrollM, true);
    } else {
      // window.addEventListener("scroll", this.windowScroll, true);
      window.removeEventListener("scroll", this.windowScroll, true);
    }
  },
};
</script>
<style src="./index.mobile.scss" lang="scss" scoped></style>
<style src="./index.scss" lang="scss" scoped></style>